import * as React from "react"
import Cookies from "js-cookie"
import { useForm, Controller } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import classNames from "classnames"
import { IoSwapHorizontalOutline } from "react-icons/io5"
import type { GoogleAddressData } from "../location"

import { EMAIL_STORAGE } from "../../constants/storage"
import { generateFormQueryParams } from "../../services/links"

import { useGoogleMaps } from "../../app/hooks/use-google-maps"

import { PlaceInputPlaceholder } from "../location-placeholder"
import { PlaceInputAutocomplete } from "../location"
import { Email } from "../email"
import { env } from "../../config"

const addressSchema = z.object({
  formatted_address: z.string(),
  city: z.string(),
  state_code: z.string(),
  lat: z.number(),
  lng: z.number(),
  zip: z.string().nullable(),
})

const transportationSchema = z.object({
  pickup: addressSchema.nullable(),
  delivery: addressSchema.nullable(),
  email: z.string().email().optional().or(z.literal("")),
})
export type Props = {
  buttonLink?: string
  buttonLabel?: string
}
export const PremiumHeroForm: React.FC<Props> = (props) => {
  const { buttonLabel, buttonLink } = props
  const APP_HOST = env("APP_HOST")
  const LINK = buttonLink ?? "/shipments/new"
  const [focusedField, setFocusedField] = React.useState<
    "pickup" | "delivery" | null
  >(null)

  const {
    handleSubmit,
    control,
    setFocus,
    watch,
    getValues,
    setValue,
    trigger,
  } = useForm<{
    pickup: null | GoogleAddressData
    delivery: null | GoogleAddressData
    email: string
  }>({
    defaultValues: {
      pickup: null,
      delivery: null,
      email: "",
    },
    resolver: zodResolver(transportationSchema),
  })

  const { mapsLoaded, loadMaps } = useGoogleMaps()

  React.useEffect(() => {
    if (mapsLoaded && focusedField != null) {
      setFocus(focusedField)
    }
  }, [mapsLoaded, focusedField, setFocus])
  const pickupValue = watch("pickup")
  const deliveryValue = watch("delivery")
  const handleSwapLocations = async () => {
    const pickupValue = getValues("pickup")
    const deliveryValue = getValues("delivery")

    setValue("pickup", deliveryValue, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    })
    setValue("delivery", pickupValue, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    })

    await trigger(["pickup", "delivery"])
  }
  const onSubmit = handleSubmit((data) => {
    let queryParams: Record<string, string | number> = {}

    if (data.pickup != null) {
      queryParams.pickupFA = data.pickup.formatted_address
      queryParams.pickupSC = data.pickup.state_code
      queryParams.pickupCt = data.pickup.city
      queryParams.pickupLat = data.pickup.lat
      queryParams.pickupLng = data.pickup.lng
      queryParams.pickupZip = data.pickup.zip ?? ""
    }

    if (data.delivery != null) {
      queryParams.deliveryFA = data.delivery.formatted_address
      queryParams.deliverySC = data.delivery.state_code
      queryParams.deliveryCt = data.delivery.city
      queryParams.deliveryLat = data.delivery.lat
      queryParams.deliveryLng = data.delivery.lng
      queryParams.deliveryZip = data.delivery.zip ?? ""
    }

    if (data.email) {
      queryParams.email = data.email
      sessionStorage.setItem(EMAIL_STORAGE, data.email)
      Cookies.set(EMAIL_STORAGE, data.email)
    }

    const formQueryParams = generateFormQueryParams({
      query: queryParams,
    })

    window.location.assign(`${APP_HOST}${LINK}${formQueryParams}`)
  })

  return (
    <form
      className="grid grid-cols-1 gap-y-6 sm:flex sm:w-full sm:gap-x-4"
      onSubmit={onSubmit}
    >
      <div className="sm:flex-1">
        <Controller
          name="pickup"
          control={control}
          render={({ field, fieldState }) => {
            const error = fieldState.error
              ? fieldState.error.message
              : undefined

            return mapsLoaded ? (
              <PlaceInputAutocomplete
                value={field.value}
                onChange={field.onChange}
                placeholder="Pickup location"
                type="pickup"
                error={error}
                id="pickup-location"
                ref={field.ref}
              />
            ) : (
              <PlaceInputPlaceholder
                value={field.value}
                onChange={field.onChange}
                placeholder="Pickup location"
                type="pickup"
                id="pickup-location"
                onFocus={() => {
                  loadMaps()
                  setFocusedField("pickup")
                }}
              />
            )
          }}
        />
      </div>
      <div className="flex w-full justify-center rotate-90 md:rotate-0 md:w-10 h-12 flex-shrink-0 items-center self-center md:pt-5 md:mt-4">
        <button
          type="button"
          onClick={handleSwapLocations}
          className={classNames(
            "p-2 rounded-full border border-1 border-gold",
            !pickupValue && !deliveryValue ? "cursor-not-allowed" : "",
          )}
          aria-label="Swap pickup and delivery locations"
          disabled={!pickupValue && !deliveryValue}
        >
          <IoSwapHorizontalOutline className="w-4 md:w-5" />
        </button>
      </div>
      <div className="sm:flex-1">
        <Controller
          name="delivery"
          control={control}
          render={({ field, fieldState }) => {
            const error = fieldState.error
              ? fieldState.error.message
              : undefined

            return mapsLoaded ? (
              <PlaceInputAutocomplete
                value={field.value}
                onChange={field.onChange}
                placeholder="Delivery location"
                type="dropoff"
                error={error}
                id="dropoff-location"
                ref={field.ref}
              />
            ) : (
              <PlaceInputPlaceholder
                value={field.value}
                onChange={field.onChange}
                placeholder="Delivery location"
                type="dropoff"
                id="dropoff-location"
                onFocus={() => {
                  loadMaps()
                  setFocusedField("delivery")
                }}
              />
            )
          }}
        />
      </div>
      <div className="sm:flex-1">
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => {
            const error = fieldState.error
              ? fieldState.error.message
              : undefined

            return (
              <div>
                <Email
                  value={field.value}
                  ref={field.ref}
                  onChange={field.onChange}
                  label="Email"
                  error={error}
                  id="email"
                />
              </div>
            )
          }}
        />
      </div>

      <div>
        <button
          className="w-full py-5 p-2 rounded-md uppercase text-center bg-blue7 hover:bg-blue-700 mt-10 text-white text-md"
          onClick={onSubmit}
        >
          {buttonLabel}
        </button>
      </div>
    </form>
  )
}
